import React, { Component } from 'react';
import Sectiontitle from '../section-title';
import './style.css';

class Rsvp extends Component {
  render() {
    return (
      <div id='rsvp' className='rsvp-area go-rsvp-area section-padding'>
        <Sectiontitle section={'Be Our Guest'} />
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8 offset-lg-2 col-md-10 offset-md-1'>
              <div className='rsvp-wrap'>
                <form action='https://formspree.io/f/xrgroppr' method='POST'>
                  <div className='contact-form form-style'>
                    <div className='col-12 col-sm-12'>
                      <input
                        type='text'
                        placeholder='Your Name*'
                        id='fname'
                        name='name'
                      />
                    </div>
                    <div className='col-12  col-sm-12'>
                      <input
                        type='text'
                        placeholder='Your Email*'
                        id='email'
                        name='email'
                      />
                    </div>
                    <div className='col col-sm-12'>
                      <select className='form-control' name='rsvp'>
                        <option disabled value=''>
                          Number Of RSVP*
                        </option>
                        <option value='1'>1</option>
                        <option value='2'>2</option>
                        <option value='3'>3</option>
                        <option value='4'>4</option>
                      </select>
                    </div>
                    <div className='col col-sm-12'>
                      <select className='form-control' name='events'>
                        <option disabled value=''placeholder="Attending">
                          Attending*
                        </option>
                        <option value='1'>All events</option>
                        <option value='2'>
                          Wedding Ceremony & After Party
                        </option>
                        <option value='3'>After Party Only</option>
                        <option value='3'>Unable to attend</option>
                      </select>
                    </div>
                    <div className='col-12  col-sm-12'>
                      <input
                        type='text'
                        placeholder='I will dance if you play...*'
                        id='song'
                        name='song'
                      />
                    </div>
                    <div className='col-12 col-sm-12'>
                      <textarea
                        className='contact-textarea'
                        placeholder='Names of attendees'
                        name='notes'
                      ></textarea>
                    </div>
                    <div className='col-12 text-center'>
                      <button id='submit' type='submit' className='submit'>
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Rsvp;
