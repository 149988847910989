import React from 'react';
import Sectiontitle from '../section-title';
import './style.css';

const People = () => {
  const alesia =
    'https://les-pageza-media.s3.amazonaws.com/raouls-pictures/alesia.jpg';
  const nina =
    'https://les-pageza-media.s3.amazonaws.com/raouls-pictures/nina.jpeg';
  const courtney =
    'https://les-pageza-media.s3.amazonaws.com/raouls-pictures/courtney.jpg';
  const logan =
    'https://les-pageza-media.s3.amazonaws.com/raouls-pictures/logan.jpg';
  const shannon =
    'https://les-pageza-media.s3.amazonaws.com/raouls-pictures/shannon.jpg';
  const roger =
    'https://les-pageza-media.s3.amazonaws.com/raouls-pictures/roger.jpg';
  const yves =
    'https://les-pageza-media.s3.amazonaws.com/raouls-pictures/yves.jpeg';
  const rene =
    'https://les-pageza-media.s3.amazonaws.com/raouls-pictures/rene-mageza.jpg';
  const robert =
    'https://les-pageza-media.s3.amazonaws.com/raouls-pictures/robert-mageza.jpg';
  const alain =
    'https://les-pageza-media.s3.amazonaws.com/raouls-pictures/alain.jpeg';

  return (
    <section id='people'>
      <div className='groomsmen-bridesmaid-area section-padding'>
        <Sectiontitle section={'Groomsmen & Bridesmaids'} />
        <div className='container'>
          <div className='groomsmen-bridesmaid-area-menu'>
            <div className='Groomsman-wrap'>
              <div className='row'>
                <div className='col-lg-3 col-md-6 col-sm-6 grid'>
                  <div className='groomsmen-bridesmaid-wrap'>
                    <div className='groomsmen-bridesmaid-img'>
                      <img src={alesia} alt='bride' />
                      {/* <div className="social-list">
                                                <ul className="d-flex">
                                                    <li><Link to='/'><span className="fa fa-facebook"></span></Link></li>
                                                    <li><Link to='/'><span className="fa fa-twitter"></span></Link></li>
                                                    <li><Link to='/'><span className="fa fa-linkedin"></span></Link></li>
                                                    <li><Link to='/'><span className="fa fa-pinterest"></span></Link></li>
                                                </ul>
                                            </div> */}
                    </div>
                    <div className='groomsmen-bridesmaid-content'>
                      <h4>Alesia Staten</h4>
                      <span>Laretia’s Sister</span>
                    </div>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-6 grid'>
                  <div className='groomsmen-bridesmaid-wrap'>
                    <div className='groomsmen-bridesmaid-img'>
                      <img src={nina} alt='bride' />
                    </div>
                    <div className='groomsmen-bridesmaid-content'>
                      <h4>Nina-Serena Burkett</h4>
                      <span>Laretia’s Childhood Friend</span>
                    </div>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-6 grid'>
                  <div className='groomsmen-bridesmaid-wrap'>
                    <div className='groomsmen-bridesmaid-img'>
                      <img src={courtney} alt='bride' />
                    </div>
                    <div className='groomsmen-bridesmaid-content'>
                      <h4>Courtney Rodgers</h4>
                      <span>Laretia’s childhood friend</span>
                    </div>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-6 grid'>
                  <div className='groomsmen-bridesmaid-wrap groomsmen-bridesmaid-wrap-2'>
                    <div className='groomsmen-bridesmaid-img'>
                      <img src={logan} alt='bride' />
                    </div>
                    <div className='groomsmen-bridesmaid-content'>
                      <h4>Logan Bryant</h4>
                      <span>Laretia’s College Friend</span>
                    </div>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-6 grid'>
                  <div className='groomsmen-bridesmaid-wrap'>
                    <div className='groomsmen-bridesmaid-img'>
                      <img src={shannon} alt='bride' />
                    </div>
                    <div className='groomsmen-bridesmaid-content'>
                      <h4>Shannon Clark</h4>
                      <span>Laretia’s College Friend</span>
                    </div>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-6 grid'>
                  <div className='groomsmen-bridesmaid-wrap groomsmen-bridesmaid-wrap-2'>
                    <div className='groomsmen-bridesmaid-img'>
                      <img src={roger} alt='bride' />
                    </div>
                    <div className='groomsmen-bridesmaid-content'>
                      <h4>Roger Mageza</h4>
                      <span>Raoul’s Brother</span>
                    </div>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-6 grid'>
                  <div className='groomsmen-bridesmaid-wrap groomsmen-bridesmaid-wrap-2'>
                    <div className='groomsmen-bridesmaid-img'>
                      <img src={yves} alt='bride' />
                    </div>
                    <div className='groomsmen-bridesmaid-content'>
                      <h4>Yves Mageza</h4>
                      <span>Raoul’s Brother</span>
                    </div>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-6 grid'>
                  <div className='groomsmen-bridesmaid-wrap'>
                    <div className='groomsmen-bridesmaid-img'>
                      <img src={rene} alt='bride' />
                    </div>
                    <div className='groomsmen-bridesmaid-content'>
                      <h4>Rene Mageza</h4>
                      <span>Raoul’s Brother</span>
                    </div>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-6 grid'>
                  <div className='groomsmen-bridesmaid-wrap groomsmen-bridesmaid-wrap-2'>
                    <div className='groomsmen-bridesmaid-img'>
                      <img src={robert} alt='bride' />
                    </div>
                    <div className='groomsmen-bridesmaid-content'>
                      <h4>Robert Mageza</h4>
                      <span>Raoul’s Brother</span>
                    </div>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-6 grid'>
                  <div className='groomsmen-bridesmaid-wrap mb-0'>
                    <div className='groomsmen-bridesmaid-img'>
                      <img src={alain} alt='bride' />
                    </div>
                    <div className='groomsmen-bridesmaid-content'>
                      <h4>Alain Hirwa</h4>
                      <span>Raoul’s Cousin</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default People;
