import React from 'react';
import Sectiontitle from '../section-title';
import './style.css';

const Couple = () => {
  const groom =
    'https://les-pageza-media.s3.amazonaws.com/raouls-pictures/groom.jpg';
  const bride ='https://les-pageza-media.s3.amazonaws.com/raouls-pictures/bride.JPG';
  return (
    <div id='couple' className='about-wrap'>
      <div className='couple-area section-padding'>
        <Sectiontitle section={'Happy Couple'} />
        <div className='container'>
          <div className='couple-wrap'>
            <div className='row'>
              <div className='col-lg-6 col-md-6 col-sm-12 couple-single'>
                <div className='couple-wrap couple-wrap-2'>
                  <div className='couple-img'>
                    <img src={groom} alt='thumb' />
                  </div>
                  <div className='couple-text'>
                    <div className='couple-content'>
                      <h3>Raoul Mageza</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-6 col-md-6 col-sm-12 couple-single'>
                <div className='couple-wrap couple-wrap-3'>
                  <div className='couple-img couple-img-2'>
                    <img src={bride} alt='thumb' />
                  </div>
                  <div className='couple-text'>
                    <div className='couple-content'>
                      <h3>Laretia Williams</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Couple;
