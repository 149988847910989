import React from 'react';
import Sectiontitle from '../section-title';
import './style.css';

const Story = () => {
  const funOne =
    'https://les-pageza-media.s3.amazonaws.com/raouls-pictures/fun-pic-1.JPG';
  const funTwo =
    'https://les-pageza-media.s3.amazonaws.com/raouls-pictures/fun-pic-2.jpg';
  const funThree =
    'https://les-pageza-media.s3.amazonaws.com/raouls-pictures/fun-pic-3.jpg';
  const funFive =
    'https://les-pageza-media.s3.amazonaws.com/raouls-pictures/fun-pic-5.jpg';
  const traditionPic =
    'https://les-pageza-media.s3.amazonaws.com/raouls-pictures/tradition-pic.jpg';
  return (
    <div id='story' className='story-area section-padding'>
      <Sectiontitle section={'Our Love Story'} />
      <div className='container'>
        <div className='story-wrap'>
          <div className='row'>
            <div className='col-lg-6 col-md-12 col-12 pr-n'>
              <div className='story-img'>
                <img src={funOne} alt='' />
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-12'>
              <div className='story-text left-align-text'>
                <h3>First time we met</h3>
                <p className= "story-paragraph">
                  Passion for international nonprofit work brought Raoul and
                  Laretia together. They first met at work at Refugee Family
                  Services before it became New American Pathways. Laretia
                  worked in human resources and Raoul worked in accounting. On
                  Raoul’s first day, Laretia made him go home to retrieve his
                  passport to complete his new hire paperwork. Laretia felt bad
                  but Raoul calmly left to take that 45 minute drive home just
                  to turn right back around.{' '}
                </p>
              </div>
            </div>
            
            <div className='col-lg-6 col-md-12 col-12'>
              <div className='story-text right-align-text'>
                <h3>Best friends</h3>
                <p>
                  Though they worked together for a few months, they didn’t talk
                  much until the staff retreat. Raoul hit some dance moves at
                  the bonfire, and Laretia thought - is this the same guy who
                  doesn’t talk much?! Has he been playing us all along, quietly
                  plugging away at figures? Their friendship began to blossom
                  from that moment. They bonded over their love for Jesus,
                  family, dancing and traveling.
                </p>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-12 pl-n'>
              <div className='story-img'>
                <img src={funTwo} alt='' />
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-12 pr-n'>
              <div className='story-img'>
                <img src={traditionPic} alt='' />
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-12'>
              <div className='story-text left-align-text'>
                <h3>Faith and Culture</h3>
                <p>
                  Raoul introduced Laretia to Rwandan culture, Afrobeats, a few
                  words in each of the four other languages he speaks, and what
                  it truly means to be grateful for every moment. He has helped
                  Laretia internalize not just in words but in action that God
                  is in control, life is bigger than present day perceived
                  inconveniences, and not to sweat the small or the big stuff.
                </p>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-12'>
              <div className='story-text right-align-text'>
                <h3>Small actions matter</h3>
                <p>
                  Laretia has shown Raoul what true love is. While they are both
                  frugal (driving old cars) Laretia has had better luck with
                  cars than Raoul. One of many incidents that made Raoul fall
                  for Laretia was when Raoul’s car broke down, and she helped
                  him push it off the road in the rain. Laretia called her dad
                  to ask him how to get Raoul’s car to start. As embarrassed as
                  Raoul was, it made him realize that she was more concerned
                  about helping him than what her dad would think about this
                  “person of interest” with an unreliable car.
                </p>
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-12 pl-n'>
              <div className='story-img'>
                <img src={funFive} alt='' />
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-12 pr-n'>
              <div className='story-img'>
                <img src={funThree} alt='' />
              </div>
            </div>
            <div className='col-lg-6 col-md-12 col-12'>
              <div className='story-text left-align-text'>
                <h3>Next Chapter</h3>
                <p>
                  Over the years, they have danced and walked through life’s
                  joys and pains together. They eagerly look forward to
                  navigating life together, growing in faith and love through
                  all life’s twists and turns with family and friends at their
                  side and God at the center of it all.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Story;
