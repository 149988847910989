import React from 'react';

// components
import Couple from '../../components/couple';
import BackgroundVideo from '../../components/hero3';
import Story from '../../components/story';
import People from '../../components/people';
import Location from '../../components/location';
// import Gallery from '../../components/gallery';
import Rsvp from '../../components/rsvp';
import Header from '../../components/Header';
import MobileMenu from '../../components/MobileMenu';
import Saveday from '../../components/countdown';

const Homepage3 = () => {
  return (
    <div>
      <Header />
      <MobileMenu />
      <BackgroundVideo />
      <Saveday />
      <Couple />
      <Story />
      <People />
      <Location />
      {/* <Gallery /> */}
      <Rsvp />
    </div>
  );
};

export default Homepage3;
