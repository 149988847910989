import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Homepage from '../HomePage/index';
// import Footer from '../../components/footer/index';
import Scrollbar from '../../components/scrollbar/index';
import './App.css';

const Main = () => {
  return (
    <div className='App'>
      <Router>
        <Switch>
          <Route exact path='/' component={Homepage} />
        </Switch>
        {/* <Footer /> */}
        <Scrollbar />
      </Router>
    </div>
  );
};

export default Main;
