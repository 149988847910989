import React from 'react';
import { Animated } from 'react-animated-css';
import classes from '../../css/BackgroundVideo.module.css';
import './style.css';

const BackgroundVideo = () => {
  const videoSource =
    'https://les-pageza-media.s3.amazonaws.com/TheProposalShort.mp4';
  return (
    <div className='vedio-area' id='home'>
      <div className={classes.Container}>
        <video autoPlay='autoplay' loop='loop' muted className={classes.Video}>
          <source src={videoSource} type='video/mp4' />
          Your browser does not support the video tag.
        </video>

        <div className={classes.Content}>
          <div className='slide-b'>
            <div className='slide-content'>
              <div className='slide-subtitle'>
                <h4>WE'RE GETTING MARRIED</h4>
              </div>
              <div className='slide-title'>
                <p>ON JANUARY 8, 2022</p>
              </div>
              {/* <div className='slide-text'>
                <p>Please RSVP by SEPTEMBER 13</p>
              </div> */}
              <Animated>
                <div className='animated-circle'></div>
              </Animated>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BackgroundVideo;
