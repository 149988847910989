import React from 'react';

import Sectiontitle from '../section-title';

import './style.css';

const Location = () => {
  const church =
    'https://les-pageza-media.s3.amazonaws.com/raouls-pictures/church.jpg';
  const dinnerHall =
    'https://les-pageza-media.s3.amazonaws.com/raouls-pictures/dinnerHall.jpg';
  const registry =
    'https://les-pageza-media.s3.amazonaws.com/raouls-pictures/registry.jpg';
  return (
    <div id='event' className='event-section section-padding'>
      <Sectiontitle section={'When & Where'} />
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className='tabs-site-button'>
              <div className='event-tabs'>
                <div className='col-md-12 col-12'>
                  <div className='event-wrap'>
                    <div className='row'>
                      <div className='col-lg-5 col-md-12 col-12'>
                        <div className='event-img'>
                          <img src={church} alt='' />
                        </div>
                      </div>
                      <div className='col-lg-7 col-md-12 col-12'>
                        <div className='event-text'>
                          <h3>Wedding Ceremony</h3>
                          <span className='church-address'>
                            January 8, 2022 at 3PM
                          </span>
                          <span className='church-address'>
                            <span>West Hunter Street Baptist Church</span>
                            <span>
                              1040 Ralph D. Abernathy Blvd. S.W. Atlanta, GA
                              30310
                            </span>
                            <br></br>
                            <span>
                              <p>Masks are required for all events. If you do not feel well, we ask that you stay home and join us via Zoom.
                                 Please alert us to your change of plans and we will provide you with the link.
                                 We encourage all to be vaccinated and boosted. For details, visit <a href="https://www.cdc.gov/coronavirus/2019-ncov/index.html?CDC_AA_refVal=https%3A%2F%2Fwww.cdc.gov%2Fcoronavirus%2Findex.html" target="_blank" rel="noopener noreferrer">cdc.gov/coronavirus</a>. Stay well!
                              </p>
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='event-wrap'>
                    <div className='row'>
                      <div className='col-lg-7 col-md-12 col-12'>
                        <div className='event-text event-text-2'>
                          <h3>Wedding Dinner</h3>
                          <span className='church-address'>
                            January 8, 2022
                          </span>
                          <span className='church-address'>
                            2011 Bolton Road Atlanta, GA 30318
                          </span>
                          <span className='church-address'>
                            **Adult only affair**
                          </span>
                          <span className='church-address'>
                            **Due to limited reception parking, we advise carpooling when possible. **
                          </span>
                        </div>
                      </div>
                      <div className='col-lg-5 col-md-12 col-12'>
                        <div className='event-img'>
                          <img src={dinnerHall} alt='' />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='event-wrap'>
                    <div className='row'>
                      <div className='col-lg-5 col-md-12 col-12'>
                        <div className='event-img'>
                          <img src={registry} alt='' />
                        </div>
                      </div>
                      <div className='col-lg-7 col-md-12 col-12'>
                        <div className='event-text'>
                          <h3>Registry & Nearby Hotel</h3>

                          <span className='church-address'>
                            <a
                              className= "our-registry"
                              rel='noopener noreferrer'
                              target='_blank'
                              href='https://www.honeyfund.com/wedding/williams-mageza-11-13-2021'
                            >
                              You can view our registry HERE
                            </a>
                            <p>Hotel Option</p>
                            <span>
                              COURTYARD ATLANTA VININGS/GALLERIA 
                            </span>
                            <span>2829 Overlook Pkwy, Atlanta, GA 30339</span>
                            <a
                              className="book-here"
                              rel='noopener noreferrer'
                              target='_blank'
                              href='https://www.marriott.com/event-reservations/reservation-link.mi?id=1638378345241&key=GRP&app=resvlink'>
                              Book Here
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Location;
